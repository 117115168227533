const menuItems = () => {
  
  let menuItemHasChildren = document.querySelectorAll('.menu-item-has-children');

    menuItemHasChildren.forEach( (item) => {

      if (item.classList.contains('current_page_ancestor')) {
        item.classList.add('current-menu-item');
        item.classList.add('current-menu-ancestor');
      }

      if (item.classList.contains('current_page_item')) {
        item.classList.add('current-menu-item');
      }

      item.addEventListener('click', function(e) {
        item.classList.toggle('current-menu-item');

        if (!item.classList.contains('current-menu-item')) {
          item.classList.toggle('current-menu-ancestor');
        }
      })
    })
  
}

const toggleMenu = () => {
  var body = document.body;
  const toggleButton = document.querySelector('.cru-header__hamburger');

  if(toggleButton) {
    toggleButton.addEventListener('click', function() {
      body.classList.toggle('cru-header__nav--active');
      toggleButton.classList.toggle('active');
    })
  }

}

menuItems()
toggleMenu()


